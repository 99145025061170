export default {
    // Residents Actions
    getResidentsAPI: 'residents',
    getResidentDetailsAPI: 'get_resident',
    getResidentOnboardingDetailsAPI: 'get_resident_details',
    getResidentWalletLedgerAPI: 'resident_wallet_ledger',
    getExitDetailsAPI: 'get_exit_details',
    exitStatusUpdateAPI: 'exit_status_update',
    confirmNocAPI: 'confirm_noc_on_exit',
    sendEmailToResidentAPI: 'ledger_history',
    createResidentCheque  : "create_resident_cheque",
    checkForOutStandingApi : "check_for_all_outstanding",

    // Onboarding Actions
    getOnboardingListAPI: 'onboarding_list',
    getOnboardingDetailsAPI: 'onboarding_details',
    confirmCheckinStudentAPI: 'confirm_check_in',
    enableAssetReverificationAPI: 'enable_asset_reverification',
    resentLinkAPI: 'resend_agreement',

    // properties
    getPropertiesList: 'properties',
    getPropertyDetail: 'properties',
    createProperty: 'properties',
    createPropertyRoomTypeMappingAPI: 'create_property_room_type_mapping',
    createPropertyBillingAPI: 'create_property_billing',
    createPropertyOwner: 'property_owners',
    getPropertyOwnerDetail:'property_owners_details',
    getBillingDetails: 'get_property_billing',
    createPropertyAssetMapping: 'create_property_asset_mapping',

    //billing
    propertyBilling : "property_billings",

    // Masters
    clustersAPI: 'clusters',
    statesAPI: 'states',
    citiesAPI: 'cities',
    roomTypesAPI: 'room_types',
    getBillTypesAPI: 'billings',
    getEmployeesAPI: 'employees',
    getpropertyEmployee: 'property_employees',
    getCommonAssetsAPI: 'property_assets',
    getRoomAssestAPI: 'property_room_assets',
    foodDataAPI: 'food_item',
    getFoodCategoryAPI:'food_category',
    getInstitutionAPI: 'institutions',
    getAccessTokenAPI:'employees/authenticate_google_oauth',

    //Blogs
    blogs : "blogs" ,
    blogAuthers : "blog_authors",
    blogCites : "get_all_cities",
    blogProperties : "get_all_properties",
    allBlogs : "get_all_blogs",
    blogCategories: "blog_categories",
    blogSection  : "blog_sections",

    //Website 
    getwebsiteAmenities : "website_amenities",
    getWebsiteTestimonials : "website_testimonials",
    getWebsiteFaqs : "website_faqs",
    getWebsiteWhyYourSpace : "website_why_your-space",
    getWebsiteCarousal : "website_carousal_banners",
    getWebsiteCity  : "cities",
    getWebsiteProperty : "website_properties",
    getWebsiteInstitution : "institutions",

    //create lead
    lead : "leads",
    sendOtp : "send_otp",
    verifyOtp : "verify" ,

    //Bulk
    bulkApproval : "approve_bulk_upload",
    // Food token verfications
    getMealSessionAPI: "property_meal_sessions",
    verifyFoodToken: "verify_food_token",

    //Bond
    residentBondStatus : "resident_bond_status",
    eligbilityCheck : "eligibility_check",
    disableBond : "disable_contract_bond",
    createBondApplicationDetails : "create_applicant_details",
    updateBondApplicationDetails : "update_applicant_details",

    //Tickets
    getTicketsAPI: 'tickets',
    updateTicketsStatusAPI: 'update_status',
    addAttachmentAPI : "add_attachments" ,
    addNotesAPI : "add_notes", 
    listNotesAPI : "list_notes"

};
