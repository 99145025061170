import { useEffect,useState, useContext } from 'react';
import { Modal } from 'react-bootstrap';

import "react-datepicker/dist/react-datepicker.css";
import '../../../css/resident-details-tab.css';
import CustomSingleDatepicker from '../../custom-datepicker/CustomSingleDatepicker';
import { fetchResidentExitDetails,updateExitStatus,confirmNoc, checkForOutStanding } from '../../../helpers/services/api.services';
import { Formik, Form, Field, FieldArray,useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import toast from "react-hot-toast";
import GlobalStateContext from '../../../GlobalStateContext';

function ExitTab({ residentId,residentInfo,residentOnboardingInfo,loadResidentsDetails,loadResidentOnboardingDetails }) {

    const {roles} = useContext(GlobalStateContext);
    const [showAbscondingModal, setShowAbscondingModal] = useState(false);
    const [showConfirmNocModal, setShowConfirmNocModal] = useState(false);
    const [showNocImagePreviewModal, setShowNocImagePreviewModal] = useState(false);
    const [showRegulariseModal, setShowRegulariseModal] = useState(false);
    const [nocType, setNocType] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [openExitModal, setOpenExitMOdal] = useState(false);
    const [paymentStatus, setPaymentStatus] = useState("pending");
    const [pendingPaymentData, setPendingPaymentData] = useState([]);

    // Abscoding Modal
    const handleCloseAbscondingModal = () => setShowAbscondingModal(false);
    const handleShowAbscondingModal = () => setShowAbscondingModal(true);

    // Confirm NOC Modal
    const handleCloseConfirmNocModal = () => setShowConfirmNocModal(false);
    const handleShowConfirmNocModal = () => {
        setInitialValues_confirm_noc(exitData.resident_room_assets)
        setShowConfirmNocModal(true)
    };

    // NOC Image Viewer Modal
    const handleCloseNocImagePreviewModal = () => setShowNocImagePreviewModal(false);
    const handleShowNocImagePreviewModal = () => setShowNocImagePreviewModal(true);

    // NOC Image Viewer Modal
    const handleCloseRegulariseModal = () => setShowRegulariseModal(false);
    const handleShowRegulariseModal = (type) => {
        setNocType(type);
        setShowRegulariseModal(true);
    }

    const [damagePercentages, setDamagePercentages] = useState([
        { label: '0%', value: 0 },
        { label: '20%', value: 20 },
        { label: '40%', value: 40 },
        { label: '60%', value: 60 },
        { label: '80%', value: 80 },
        { label: '100%', value: 100 },
    ]);

    const [exitData,setExitData] = useState({})

    // const [status,setStatus] = useState("active")
    const [internalOperation,setInternalOperation] = useState("")
    const [showTerminteModel, setShowTerminateModel] = useState(false)
    const [initialValues_confirm_noc,setInitialValues_confirm_noc] = useState([])
    const [showViewNocModel,setShowViewNocModal] = useState(false)
    // const [isExitInitiated, setIsExitInitiated] = useState(false)

    useEffect(() => {
        checkForOutStandingBills();
        loadExitDetails();
      }, [residentId]);

      const checkForOutStandingBills = async () => {
        try {
          const response = await checkForOutStanding(residentId);
          if (response.status === "success") {
            if (response?.data?.length > 0) {
              setPaymentStatus("pending");
              setPendingPaymentData(response?.data);
            } else if (response?.data?.length == 0) {
              setPaymentStatus("complete");
              setPendingPaymentData(response?.data);
            }
          } else {
            setPendingPaymentData(null);
          }
        } catch (error) {
          console.log(error);
        }
      };


    const loadExitDetails = async () => {
        let response = await fetchResidentExitDetails(residentId);
        if(response.status === "success"){
            setExitData(response.data)
        }
    }

    const initialValues ={
        // date:"",
        status:'absconded',
        absconded_reason:"",
      }

      const validationSchema = Yup.object().shape({
        date: Yup.date(),
        status: Yup.string(),
        absconded_reason: Yup.string(),
      });
    
      const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values) =>  {
            try {
                delete values.date
                values.absconded_reason = values.absconded_reason.replace(/(\r\n|\n|\r)/gm, ' ');
                await updateExitStatus(residentId,values)
                await toast.success('Exit status has been updated successfully.');
                loadResidentsDetails();
                loadResidentOnboardingDetails();
                handleCloseAbscondingModal();
                checkForOutStandingBills();
                loadExitDetails();
                formik.resetForm()
            } catch (error) {
                toast.error(error?.response?.data?.error || "Something went wrong please try again");
            }
        },
      });

      const initialValues_terminate ={
        // status:"",
        portend_amount:"",
      }

      const validationSchema_terminate = Yup.object().shape({
        status: Yup.string(),
        portend_amount: Yup.number().positive("Partend amount must be positive number").integer("Partend amount must be an integer"),
      });

      const formik_terminate = useFormik({
        initialValues:initialValues_terminate,
        validationSchema:validationSchema_terminate,
        onSubmit: async (values) =>  {
            try {
                await updateExitStatus(residentId,{...values, status:nocType})
                await toast.success('Exit status has been updated successfully.');
                formik_terminate.resetForm();
                loadResidentsDetails();
                loadResidentOnboardingDetails();
                handleCloseRegulariseModal();
                checkForOutStandingBills();
                loadExitDetails();
            } catch (error) {
                toast.error(error?.response?.data?.error || "Something went wrong please try again");
            }
        },
      });

      const handleMarkAsLeftClick = () => {
        if (paymentStatus == "pending") {
          handleShowAbscondingModal("abscond");
        }
        if (paymentStatus == "complete") {
          setOpenExitMOdal(true);
        }
      };
    
      const handleConfirmExitSubmitHandle = async()=>{
        try {
    
          const data = {
            status : "exit_initiated"
          }
    
          const response = await updateExitStatus(residentId , data)
    
          if(response.status == "success"){
            setOpenExitMOdal(false);
            toast.success("Exit initiated")
            loadExitDetails();
          }
          
        } catch (error) {
            toast.error(error?.response?.data?.error || "Something went wrong please try again");
        }
      }

    return (
        <>
            <div className='mob_details_tab_box exit_mob_tab '>
                <div className='exit_tab_box'>
                    {/* {
                        isExitInitiated ? */}
                    {
                        exitData?.is_exit_journey_started ?
                        <div className='col-12 alert_warring_box mb-3'>
                            Exit confirmation trigger initiated to Resident. Pending confirmation
                        </div>
                        :null
                    }

                    {
                        residentInfo?.contract_details?.exit_confirmed ?
                            <div className='col-12 alert_success_box mb-3'>
                                Student Confirmed on Exit
                            </div>
                        : null
                    }
                    
                    {
                        residentInfo?.contract_details?.status === "absconded" ?
                        <div className='col-12 alert_error_box mb-3'>
                            The resident has absconded on {residentInfo?.contract_details?.contract_start_date?
                                    moment(residentInfo.contract_details.contract_start_date).format('DD-MM-YYYY')
                                    :"--"}
                        </div>
                        :null
                    }

                    {
                        residentInfo?.contract_details?.status === "terminated" ?
                        <div className='col-12 alert_error_box mb-3'>
                            The resident is terminated
                        </div>
                        :null
                    }

                    {
                        exitData?.resident_transfer_details && exitData?.is_extend_journey_started ?
                            <div className='col-12 alert_success_box mb-3'>
                                Student Confirmed on Transfer
                            </div>
                        : null
                    } 

                    <div className='table_main_tit pb-3'>
                        <h2>Summary</h2>
                    </div>
                    <div className='mb-4'>
                        <div className='justify-content-between align-items-center exit_top'>
                            <div className='small_main_box '>
                                <div className='mb-3 mx-0 small_box_data justify-content-between align-items-center d-flex bg_white'>
                                    <h2 className='m-0'>Move-Date as per contract</h2>
                                    {/* <span>2345</span> */}
                                    <span>{residentInfo?.contract_details?.contract_start_date?
                                    moment(residentInfo.contract_details.contract_start_date).format('DD-MM-YYYY')
                                    :"--"}</span>
                                </div>
                                <div className='mx-0 small_box_data justify-content-between align-items-center d-flex bg_white'>
                                    <h2 className='m-0'>Proposed Refunds</h2>
                                    {/* <span>60,000</span> */}
                                    <span>{exitData?.proposed_refund ? String(exitData?.proposed_refund) : 0}</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        residentInfo?.contract_details?.status === "absconded" ?
                        <div className='brd_box_card'>
                            <div className="box_head">
                                <div className='d-flex align-items-center justify-content-between'>
                                    <h2> <img src="/images/corner-down-right.svg" className="pr-2" alt="" /> Abscond Details </h2>
                                </div>
                            </div>
                            <div className='box_contain'>
                                <div className='sub_exit_box'>
                                    <div className='align-items-center'>
                                        <div className='d-flex align-items-center justify-content-between sub_exit_data_txt'>
                                            <h3>Abscond date</h3>
                                            <span>{residentInfo?.contract_details?.absconded_on ?
                                                moment(residentInfo.contract_details.absconded_on).format('DD-MM-YYYY')
                                                : "--"
                                            }</span>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between sub_exit_data_txt'>
                                            <h3>Comment by PM</h3>
                                            <span>{residentInfo?.contract_details?.absconded_reason ? residentInfo.contract_details.absconded_reason : "--" }</span>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between sub_exit_data_txt'>
                                            <h3>Contract Value Due</h3>
                                            <span>{residentInfo?.contract_details?.contract_value ? residentInfo.contract_details.contract_value : "--"}</span>
                                        </div>
                                        <div className='d-flex align-items-center justify-content-between sub_exit_data_txt'>
                                            <h3>SDM Amount</h3>
                                            <span>Rooms, Services & Cleanliness </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : null
                    }


                    <div className='brd_box_card'>
                        <div className="box_head">
                            <div className='d-flex align-items-center justify-content-between'>
                                <h2> <img src="/images/corner-down-right.svg" className="pr-2" alt="" /> Resident Exit Details</h2>
                                {
                                    exitData?.is_exit_journey_started ?
                                        <div className="tit_point green_point">Initiated</div>
                                    :
                                    <div className="tit_point">{residentInfo?.basic_details?.ys_id ?? ""}</div>
                                }
                            </div>
                        </div>
                        <div className='box_contain'>
                            <div className='sub_exit_box'>
                                <div className='align-items-center'>
                                    <div className='d-flex align-items-center justify-content-between sub_exit_data_txt'>
                                        <h3>Exit confirmation date</h3>
                                        <span>{exitData?.exit_confimation_date ?
                                                moment(exitData.exit_confimation_date).format('DD-MM-YYY')
                                                : "--"
                                            }</span>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between sub_exit_data_txt'>
                                        <h3>Asset Checklist</h3>
                                        {
                                            exitData?.asset_checklist_completed ?
                                                <div className="green_point d-inline">Completed</div>
                                            : 
                                                <span className="gray_point d-inline">Not Completed</span>
                                        }
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between sub_exit_data_txt'>
                                        <h3>Exit Rating</h3>
                                        <span>
                                        {exitData?.exit_review ?
                                                exitData.exit_review
                                                : "--"
                                            }
                                        </span>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between sub_exit_data_txt'>
                                        <h3>Exit Reason</h3>
                                        <span>
                                        {exitData?.exit_reason ?
                                                exitData.exit_reason
                                                : "--"
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className='sub_exit_box'>
                                <h2>Refund bank account</h2>
                                <div className='align-items-center '>
                                    <div className='d-flex align-items-center justify-content-between sub_exit_data_txt'>
                                        <h3>Name as per Bank</h3>
                                        <span>
                                        {exitData?.bank_details?.account_holder_name ?
                                                exitData.bank_details.account_holder_name
                                                : "--"
                                            }
                                        </span>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between sub_exit_data_txt'>
                                        <h3>Account Number</h3>
                                        <span>
                                        {exitData?.bank_details?.account_number ?
                                                exitData.bank_details.account_number
                                                : "--"
                                            }
                                        </span>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between sub_exit_data_txt'>
                                        <h3>IFSC Code</h3>
                                        <span>
                                        {exitData?.bank_details?.ifsc_code ?
                                                exitData.bank_details.ifsc_code
                                                : "--"
                                            }
                                        </span>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-between sub_exit_data_txt'>
                                        <h3>Bank Name - Branch</h3>
                                        <span>
                                        {exitData?.bank_details?.branch_address ?
                                                exitData.bank_details.branch_address
                                                : "--"
                                            }
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                    {
                        residentInfo?.contract_details?.noc_confirmed ?
                            <div className='justify-content-between align-items-center exit_top mb-3'>
                                <button
                                    className="orange_brd_button p-12-32-button"
                                    onClick={()=>setShowViewNocModal(true)}
                                >view NOC by pm</button>
                            </div>
                        : (roles?.super_admin || roles?.admin || roles?.property_manager || roles?.warden || roles?.finance_manager || roles?.finance_head || roles?.cfo) && exitData?.asset_checklist_completed ?
                                <div className='mb-3'>
                                    <button className='basic-button w-100' onClick={handleShowConfirmNocModal}>Confirm NOC</button>
                                </div>
                        : null
                    }

                    {
                        exitData?.resident_transfer_details && exitData?.is_extend_journey_started ?
                            <div className='brd_box_card'>
                                <div className="box_head">
                                    <div className='d-flex align-items-center'>
                                        <h2> <img src="/images/switch-horizontal.svg" className="pr-2" alt="" /> Resident Transfer Details</h2>
                                    </div>
                                </div>
                                <div className='box_contain'>
                                    <div className='sub_exit_box'>
                                        <div className='align-items-center'>
                                            <div className='d-flex align-items-center justify-content-between sub_exit_data_txt'>
                                                <h3>Lead creation date</h3>
                                                <span>{exitData?.resident_transfer_details?.new_lead_creation_date ?
                                                    moment(exitData.resident_transfer_details.new_lead_creation_date).format('DD-MM-YYY')
                                                    : "--"
                                                }</span>
                                            </div>
                                            <div className='d-flex align-items-center justify-content-between sub_exit_data_txt'>
                                                <h3>Lead ID</h3>
                                                <span>{exitData?.resident_transfer_details?.new_lead_id ?
                                                    exitData.resident_transfer_details.new_lead_id
                                                    : "--"
                                                }</span>
                                            </div>
                                            <div className='d-flex align-items-center justify-content-between sub_exit_data_txt'>
                                                <h3>Preferred room</h3>
                                                {
                                                    exitData?.resident_transfer_details?.room_change ?
                                                        <div className="green_point d-inline">Different</div>
                                                    : 
                                                        <span className="gray_point d-inline">Current</span>
                                                }
                                            </div>
                                            <div className='d-flex align-items-center justify-content-between sub_exit_data_txt'>
                                                <h3>Preferred property</h3>
                                                {
                                                    exitData?.resident_transfer_details?.is_same_property ?
                                                        <div className="green_point d-inline">Current</div>
                                                    : 
                                                        <span className="gray_point d-inline">Different</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        : null
                    }

                    {pendingPaymentData && (
                            <>
                            {exitData?.contract_details?.status == "absconded" ||
                exitData?.contract_details?.status ==
                  "terminated" ? null : roles?.super_admin ||
                  roles?.admin ||
                  roles?.property_manager ||
                  roles?.warden ||
                  roles?.finance_manager ||
                  roles?.finance_head ||
                  roles?.cfo ||
                  roles?.customer_care ? (
                  <button
                    className="orange_brd_button p-16-20-button"
                    onClick={handleMarkAsLeftClick}
                  >
                    Mark as Left
                  </button>
                ) : null}
              </>
            )}

                    {
                        residentInfo?.contract_details?.status === "absconded" ?
                        roles?.super_admin || roles?.finance_manager || roles?.finance_head || roles?.cfo ?
                        <>
                            <div className='mt-4 d-flex align-items-center justify-content-between'>
                                <button
                                    className="w48 orange_brd_button p-12-32-button"
                                    onClick={() => handleShowRegulariseModal('active')}
                                >REGULARISE</button>
                                <button
                                    className="w48 basic-button"
                                    onClick={() => handleShowRegulariseModal('terminated')}
                                >TERMINATE</button>
                            </div>
                        </>
                        : null
                        : null
                    }

                </div>
            </div>

            {/* ABSCONDING MODAL */}
            <Modal
                show={showAbscondingModal}
                onHide={handleCloseAbscondingModal}
                centered
                size="md"
                className='abscoding_modal'
                backdrop="static"
            >
                <Modal.Body>
                    <div className='head_part_date_modal'>
                        <span onClick={handleCloseAbscondingModal} className='close_modal'>
                            <img src='/images/cross_modal.svg' alt='Close Icon' />
                        </span>
                        <h2>Confirm Abscond Status</h2>
                        <p>Are you sure you want to mark this resident as Abscond? An email notification will also be sent to the resident informing them of this status.</p>
                    </div>
                    <form onSubmit={formik.handleSubmit} id="abscond_form">
                        <div className='modal_input_main'>
                            <div className="form-group">
                                <label htmlFor="exampleFormControlSelect1">Enter Date</label>
                                <CustomSingleDatepicker />
                            </div>
                            <div className="form-group">
                                <textarea 
                                    className="form-control" 
                                    placeholder='Additional info (Optional)'
                                    id="absconded_reason"
                                    name="absconded_reason"
                                    value={formik.values.absconded_reason}
                                    onChange={(e) => formik.setFieldValue('absconded_reason', e.target.value)}
                                ></textarea>
                            </div>
                            <div className="d-flex mt-2" style={{ fontSize: "12px", color: "red" }}>
                Oops! Payment is due - 
                  <a
                    href={`/residents-details/${residentId}`}
                    style={{ textDecoration : "underline" ,  color: "red" }}
                  >
                    {" "}
                     Check Now.
                  </a>
                </div>

                            <div className='modal_btn_footer'>
                                <button type='submit' className="basic-button w-100 mb-3">MARK AS ABSCOND</button>
                                <button
                                    type="button"
                                    onClick={handleCloseAbscondingModal}
                                    className="orange_brd_button p-12-16-button mr-3"
                                >No, CANCEL</button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>

            {/* CONFIRM NOC MODAL */}
            <Modal
                show={showConfirmNocModal}
                onHide={handleCloseConfirmNocModal}
                centered
                size="md"
                className='confirm_noc_modal mob_exit_confirm'
            >
                <Modal.Body>
                <Formik
                    initialValues={{ resident_room_assets: initialValues_confirm_noc}}
                    onSubmit={async (values) =>{
                    const finalPayload = values.resident_room_assets.map((asset)=>({id:asset.id,damage_percent:asset.damage_percent}))
                    //   console.log("finalPayload -- ",finalPayload)
                    try {
                        await confirmNoc(residentId,{resident_room_assets:finalPayload})
                        await toast.success('NOC has been confirmed successfully.');
                        handleCloseConfirmNocModal();
                    } catch (error) {
                        await toast.error('Something went wrong while updating NOC confirmation.');
                    }
                    }}
                    render={(props) => (
                        <Form>
                            <div className='head_part_date_modal exit_modal_header'>
                                <div className="table_main_tit d-flex justify-content-between align-items-center px-20 py-3">
                                    <div>
                                        <h2 className='m-0'>
                                            <img src="/images/Back.svg" className="pr-2" alt="" onClick={handleCloseConfirmNocModal} />
                                            Verify Assets
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className='modal_data_part px-20 py-3'>
                        <div className='d-flex justify-content-between align-items-center mb-4 '>
                            <div className='mob_exit_main_tit'>
                                <h2>Asset Verification</h2>
                                <p className='m-0'>Kindly check the assets and enter the damage percentage</p>
                            </div>
                        </div>
                        <div className='exit_mob_tab_confirm_scroll'>
                            {/* <div className='mob_exit_list_box'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <h4>CHAIR <span className='bg_green_txt'>0% Damage</span></h4>
                                    <h5>₹ 10,000</h5>
                                </div>
                                <div className='list_part_exit_mob'>
                                    <div className='row'>
                                        <div className='col-3'>
                                            <div className='mob_exit_list_img'>
                                                <img alt="" src='/images/mob_exit_small.png' onClick={handleShowNocImagePreviewModal} />
                                            </div>
                                        </div>
                                        <div className='col-3'>
                                            <div className='mob_exit_list_img'>
                                                <img alt="" src='/images/mob_exit_small.png' onClick={handleShowNocImagePreviewModal} />
                                            </div>
                                        </div>
                                        <div className='col-3'>
                                            <div className='mob_exit_list_img'>
                                                <img alt="" src='/images/mob_exit_small.png' onClick={handleShowNocImagePreviewModal} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='modal_input_main mt-12'>
                                    <div className="form-group">
                                        <select className="form-control">
                                            <option>Select damage percentage</option>
                                            {damagePercentages.map((percnt, index) => <option value={percnt?.value} key={index}>{percnt?.label}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='mob_exit_list_box'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <h4>TABLE <span className='bg_warning_txt'>20% Damage</span></h4>
                                    <h5>₹ 10,000</h5>
                                </div>
                                <div className='list_part_exit_mob'>
                                    <div className='row'>
                                        <div className='col-3'>
                                            <div className='mob_exit_list_img'> <img alt="" src='/images/mob_exit_small.png' /></div>
                                        </div>
                                        <div className='col-3'>
                                            <div className='mob_exit_list_img'> <img alt="" src='/images/mob_exit_small.png' /></div>
                                        </div>
                                        <div className='col-3'>
                                            <div className='mob_exit_list_img'> <img alt="" src='/images/mob_exit_small.png' /></div>
                                        </div>

                                    </div>
                                </div>
                                <div className='modal_input_main mt-12'>
                                    <div className="form-group">
                                        <select className="form-control">
                                            <option>Select damage percentage</option>
                                            {damagePercentages.map((percnt, index) => <option value={percnt?.value} key={index}>{percnt?.label}</option>)}
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='mob_exit_list_box'>
                                <div className='d-flex justify-content-between align-items-center'>
                                    <h4>CUPBOARD <span className='bg_danger_txt'>60% Damage</span></h4>
                                    <h5>₹ 10,000</h5>
                                </div>
                                <div className='list_part_exit_mob'>
                                    <div className='row'>
                                        <div className='col-3'>
                                            <div className='mob_exit_list_img'> <img alt="" src='/images/mob_exit_small.png' /></div>
                                        </div>
                                        <div className='col-3'>
                                            <div className='mob_exit_list_img'> <img alt="" src='/images/mob_exit_small.png' /></div>
                                        </div>
                                        <div className='col-3'>
                                            <div className='mob_exit_list_img'> <img alt="" src='/images/mob_exit_small.png' /></div>
                                        </div>

                                    </div>
                                </div>
                                <div className='modal_input_main mt-12'>
                                    <div className="form-group">
                                        <select className="form-control">
                                            <option>Select damage percentage</option>
                                            {damagePercentages.map((percnt, index) => <option value={percnt?.value} key={index}>{percnt?.label}</option>)}
                                        </select>
                                    </div>
                                </div>
                                </div> */}

                                        <FieldArray
                                            name="friends"
                                            render={arrayHelpers => (
                                            <>
                                                {props.values.resident_room_assets && props.values.resident_room_assets.length > 0 ? (
                                                    props.values.resident_room_assets.map((asset, index) => (
                                                    <div className='mob_exit_list_box' key={`${asset.id}_${index}`}>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <h4>{asset?.asset_slug} 
                                                                    {/* <span className='bg_danger_txt'>60% Damage</span> */}
                                                            </h4>
                                                            <h5>₹ {asset?.asset_amount ? asset.asset_amount : '0'}</h5>
                                                        </div>
                                                        <div className='list_part_exit_mob'>
                                                            <div className='row'>
                                                                <div className='col-3'>
                                                                    <div className='mob_exit_list_img'> <img alt="" src={asset.uploaded_asset_url} /></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='modal_input_main mt-12'>
                                                            <div className="form-group">
                                                                <select 
                                                                    id={`asset.${asset.id}`}
                                                                    name={`asset.${asset.id}`}
                                                                    className="form-control"
                                                                    value={props.values.resident_room_assets[index].damage_percent}
                                                                    onChange={(e)=> props.setFieldValue(`resident_room_assets[${index}].damage_percent`,e.target.value)}
                                                                >
                                                                    <option>Select damage percentage</option>
                                                                    {damagePercentages.map((percnt, index) => <option value={percnt?.value} key={index}>{percnt?.label}</option>)}
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                                ) :
                                                    null
                                                }
                                            </>
                                            )}
                                        />
                            </div>
                        </div>
                        <div className='px-20'>
                            <button
                                type='submit'
                                className="basic-button p-16-32-button w-100 mb-3"
                            >Save</button>
                            <button
                                type='button'
                                className="orange_brd_button p-16-32-button w-100 mb-3"
                                onClick={handleCloseConfirmNocModal}
                            >CANCEL</button>
                        </div>
                    </Form>)} 
                />
                </Modal.Body>
            </Modal>

            {/* VIEW NOC MODAL */}
            <Modal
                show={showViewNocModel}
                onHide={()=>setShowViewNocModal(false)}
                centered
                size="md"
                className='confirm_noc_modal mob_exit_confirm'
            >
                <Modal.Body>
                            <div className='head_part_date_modal exit_modal_header'>
                                <div className="table_main_tit d-flex justify-content-between align-items-center px-20 py-3">
                                    <div>
                                        <h2 className='m-0'>
                                            <img src="/images/Back.svg" className="pr-2" alt="" onClick={()=>setShowViewNocModal(false)} />
                                            Verify Assets
                                        </h2>
                                    </div>
                                </div>
                            </div>
                            <div className='modal_data_part px-20 py-3'>
                        <div className='d-flex justify-content-between align-items-center mb-4 '>
                            <div className='mob_exit_main_tit'>
                                <h2>Asset Verification</h2>
                                <p className='m-0'>Kindly check the assets and enter the damage percentage</p>
                            </div>
                        </div>
                        <div className='exit_mob_tab_confirm_scroll'>
                                                { exitData?.resident_room_assets && exitData.resident_room_assets?.length > 0 ? (
                                                    exitData.resident_room_assets.map((asset, index) => (

                                                    <div className='mob_exit_list_box' key={`${asset.id}_${index}`}>
                                                        <div className='d-flex justify-content-between align-items-center'>
                                                            <h4>{asset?.asset_slug} <span className='bg_danger_txt'>{`${asset?.damage_percent ?? 0}% Damage`}</span></h4>
                                                            <h5>₹ {asset?.asset_amount ? asset.asset_amount : '0'}</h5>
                                                        </div>
                                                        <div className='list_part_exit_mob'>
                                                            <div className='row'>
                                                                <div className='col-3'>
                                                                    <div className='mob_exit_list_img'> <img alt="" src={asset.uploaded_asset_url} /></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                                ) :
                                                    null
                                                }
                                    
                            </div>
                        </div>
                        <div className='px-20'>
                            <button
                                className="basic-button p-16-32-button w-100 mb-3"
                                onClick={()=>setShowViewNocModal(false)}
                            >OK</button>
                        </div>
                </Modal.Body>
            </Modal>


            {/* IMAGE VIEWER MODAL */}
            <Modal
                show={showNocImagePreviewModal}
                onHide={handleCloseNocImagePreviewModal}
                centered
                size="md"
                className='noc_image_viewer mob_slider_pop'
            >
                <Modal.Body>
                    {/* Header */}
                    <div className="table_main_tit d-flex justify-content-between align-items-center">
                        <h2 className='m-0'>Preview photos</h2>
                        <span className="close_modal" onClick={handleCloseNocImagePreviewModal}><img src="/images/cross_modal.svg" alt="Close Icon" /></span>
                    </div>

                    {/* Image Viewer */}
                    <div className='noc_image_viewer'>
                        <div className='preview_middle_box'>
                            <h2>Cupboard</h2>
                            <div className='photo_slider_main'>
                                <div className='slider_big_photo'>
                                    <img alt="" src="/images/mob_exit_big.png" />
                                </div>
                                <div className='slider_small_main_wapper'>
                                    <div className='slider_small_main '>
                                        <div className='slider_small_photo active'>
                                            <img alt="" src="/images/mob_exit_small.png" />
                                        </div>
                                        <div className='slider_small_photo'>
                                            <img alt="" src="/images/mob_exit_small.png" />
                                        </div>
                                        <div className='slider_small_photo'>
                                            <img alt="" src="/images/mob_exit_small.png" />
                                        </div>
                                        <div className='slider_small_photo'>
                                            <img alt="" src="/images/mob_exit_small.png" />
                                        </div>
                                        <div className='slider_small_photo'>
                                            <img alt="" src="/images/mob_exit_small.png" />
                                        </div>
                                        <div className='slider_small_photo'>
                                            <img alt="" src="/images/mob_exit_small.png" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Image Galary */}

                </Modal.Body>
            </Modal>

            {/* REGULARISE & TERMINATE MODAL */}
            <Modal
                show={showRegulariseModal}
                onHide={handleCloseRegulariseModal}
                centered
                ize="md"
                className='abscoding_modal'
                backdrop="static"
            >
                <Modal.Body>
                    <div className='head_part_date_modal'>
                        <span onClick={handleCloseRegulariseModal} className='close_modal'>
                            <img src='/images/cross_modal.svg' alt='Close Icon' />
                        </span>
                        <h2>{(nocType === 'active') ? 'Regularise' : 'Terminate'} Resident</h2>
                        <p>To {(nocType === 'active') ? 'regularise' : 'terminate'} the resident, enter the amount to be waived off.</p>
                    </div>
                    <form onSubmit={formik_terminate.handleSubmit} id="terminte_form">
                        <div className='modal_input_main'>
                            <div className="form-group">
                                <input 
                                    id="portend_amount"
                                    name="portend_amount"
                                    type='number' 
                                    className='form-control' 
                                    placeholder='Enter partend amount'
                                    value={formik_terminate.values.portend_amount}
                                    style={formik_terminate.touched.portend_amount && formik_terminate.errors.portend_amount ? {
                                        borderColor:'red'
                                    }: null}
                                    onChange={(e) => formik_terminate.setFieldValue('portend_amount', e.target.value)} 
                                    onBlur={formik_terminate.handleBlur}
                                />
                                 {
                                        formik_terminate.touched.portend_amount && formik_terminate.errors.portend_amount ? (
                                            <div className="error-label">{formik_terminate.errors.portend_amount}</div>
                                        ) : null
                                }
                            </div>

                            <div className='modal_btn_footer'>
                                <button type='submit' className="basic-button w-100 mb-3">YES, CONFIRM</button>
                                <button
                                    type="button"
                                    onClick={handleCloseRegulariseModal}
                                    className="orange_brd_button p-12-16-button mr-3"
                                >No, CANCEL</button>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            <Modal
        show={openExitModal}
        onHide={() => setOpenExitMOdal(false)}
        centered
        size="md"
        className="abscoding_modal"
      >
        <Modal.Body>
          <div className="head_part_date_modal">
            <span
              onClick={() => setOpenExitMOdal(false)}
              className="close_modal"
            >
              <img src="/images/cross_modal.svg" alt="Close Icon" />
            </span>
            <div className="d-flex justify-content-between align-items-center mb-4">
              <div>
                <h2>Confirm Left Status</h2>
                <p className=' mt-2 text-success'>No payment is due</p>
                <p className="m-0">
                Are you sure you want to mark this resident as LEFT? You may also check with the resident regarding any potential extensions or renewals if they wish to continue.
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="orange_brd_button p-12-16-button mr-3  w-100"
            onClick={handleConfirmExitSubmitHandle}
          >
            MARK AS LEFT
          </button>
          <button
            type="submit"
            className="basic-button w-100 "
            onClick={() => setOpenExitMOdal(false)}
          >
            CANCEL
          </button>
        </Modal.Footer>
      </Modal>
        </>
    );
}

export default ExitTab;