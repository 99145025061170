import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  createLead,
  fetchCities,
  sendLeadOtp,
  verifyLeadOtp,
} from "../../helpers/services/admin.services";
import { fetchProperties } from "../../helpers/services/api.services";
import { Form } from "react-bootstrap";
import toast from "react-hot-toast";
import axios from "axios";
import "../../css/loader.css";

const MobileLeadModal = ({ showModal, setShowModal, property }) => {
  const [mobileNumber, setMobileNumber] = useState("");
  const [residentName, setResidentName] = useState("");
  const [email, setEmail] = useState("");
  const [propertyId, setPropertyId] = useState("");
  const [verified, setVerified] = useState(false);
  const [propertyData, setPropertyData] = useState([]);
  const [sentOtp, setSentOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [errorOtp, setErrorOtp] = useState(false);
  const [otpCount, setOtpCount] = useState(0);
  const [loding , setLoading] = useState(false)
  const [submitLoader , setSubmitLoader] = useState(false)

  const handleSendOtp = async () => {
    setLoading(true)
    setErrorOtp(false);
    if (mobileNumber.length > 10) {
      return toast.error("Mobile number cannot be greater that 10 digit ");
    }

    if (otpCount >= 3) {
      setOtp("");
      return toast.error(
        "Exceed the maximum amount of OTP; please try again after some time"
      );
    }

    const data = {
      mobile_number: mobileNumber,
    };
    const response = await sendLeadOtp(data);
    if (response.status == "success") {
      setSentOtp(true);
      setOtpCount(otpCount + 1);
      toast.success("Otp sent");
    } else {
      toast.error("Error while sending the otp");
    }
    setLoading(false)
  };

  const hanldeVerifyNumber = async () => {
    setLoading(true)
    const data = {
      mobile_number: mobileNumber,
      otp: otp,
    };
    const response = await verifyLeadOtp(data);
    if (response.status == "success") {
      setVerified(true);
      setErrorOtp(false);
      setOtpCount(0);
      toast.success("Otp verified");
    } else {
      setOtp("");
      setErrorOtp(true);
      toast.error(response.data.errors);
    }
    setLoading(false)
  };

  const fetchProperty = async () => {
    try {
      let response = await fetchProperties(1, {}, {}, 1000);

      if (
        response &&
        response?.status === "success" &&
        response.data &&
        response.data.length > 0
      ) {
        setPropertyData(response.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const clearData = () => {
    setEmail("");
    setMobileNumber("");
    setPropertyId("");
    setMobileNumber("");
    setVerified(false);
    setOtp("");
    setSentOtp(false);
    setOtpCount(0);
  };

  const handleSubmit = async () => {

    if(!property?.id && !propertyId){
      return toast.error("Please select property")
    }

    if( !residentName.trim()){
      return toast.error("Please enter resident name")
    }
    const data = {
      resident_name: residentName.trim(),
      email: email,
      mobile_number: mobileNumber,
      property_id: property ? property?.id : propertyId,
      lead_generation_source: "Walk-in",
    };

    setSubmitLoader(true)

    try {
      const response = await createLead(data);
      if (response.status == "success") {
        toast.success("New lead created");
        clearData();
        setShowModal(false);
      } else {
        toast.error(`${response.data.errors}`);
      }
    } catch (error) {
      console.log(error);
    }finally{
      setSubmitLoader(false)
    }
  };

  useEffect(() => {
    fetchProperty();
  }, []);

  return (
    <div>
      <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
          clearData();
        }}
        centered
        size="lg"
        className="check_popup_mobile"
      >
        <Modal.Header>
          <Modal.Title>
            {" "}
            <b>New Lead</b>{" "}
          </Modal.Title>
          <img
            src="/images/cross_modal.svg"
            className="cursor-pointor"
            alt="Close Icon"
            onClick={() => {
              setShowModal(false);
              clearData();
            }}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 col-lg-6 col-sm-6">
              <div style={{ position: "relative" }}>
                <div className="form_control_box">
                  <label htmlFor="mobileNumber">Mobile Number</label>
                  <input
                    type="number"
                    id="mobileNumber"
                    name="mobileNumber"
                    placeholder="Enter Mobile number"
                    className="form-control"
                    value={mobileNumber}
                    disabled={sentOtp}
                    onChange={(e) => setMobileNumber(e.target.value)}
                  />
                </div>
                {!sentOtp && mobileNumber.length > 9 && (
                  <div
                    style={{
                      position: "absolute",
                      top: "35px",
                      right: "30px",
                      color: "#FF5700",
                      cursor: "pointer",
                    }}
                    onClick={handleSendOtp}
                  >
                   {loding ? <div className="loader-small"></div> : <><b>Send OTP</b></>}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-12 col-lg-6 col-sm-6">
              <div style={{ position: "relative" }}>
                <div className="form_control_box">
                  <label htmlFor="mobileNumber">Enter Otp</label>
                  <input
                    type="number"
                    id="otp"
                    name="otp"
                    placeholder="Enter 6 digit number"
                    className="form-control"
                    value={otp}
                    disabled={sentOtp ? false : true}
                    onChange={(e) => setOtp(e.target.value)}
                  />
                </div>
                {!verified && sentOtp && otp.length > 5 && !errorOtp && (
                  <div
                    style={{
                      position: "absolute",
                      top: "35px",
                      right: "30px",
                      color: "#FF5700",
                      cursor: "pointer",
                    }}
                    onClick={hanldeVerifyNumber}
                  >
                    {loding ? <div className="loader-small"></div> : <><b>Verify</b></>}
                  </div>
                )}
                {!verified && sentOtp && errorOtp && (
                  <div
                    style={{
                      position: "absolute",
                      top: "37px",
                      right: "8px",
                      color: "#FF5700",
                      cursor: "pointer",
                    }}
                    onClick={handleSendOtp}
                  >
                    <b>Resend OTP</b>
                  </div>
                )}
              </div>
            </div>
            {verified && (
              <>
                <div className="col-md-4 col-lg-6 col-sm-6">
                  <div className="form_control_box">
                    <label htmlFor="email">Email</label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      placeholder="Enter Email"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-md-4 col-lg-6 col-sm-6">
                  <div className="form_control_box">
                    <label htmlFor="residentName">Resident Name</label>
                    <input
                      type="text"
                      id="residentName"
                      name="residentName"
                      placeholder="Enter Resident Name"
                      className="form-control"
                      value={residentName}
                      onChange={(e) => setResidentName(e.target.value)}
                    />
                  </div>
                </div>
                {!property &&
                  <div className="col-md-4 col-lg-6 col-sm-6">
                  <div className="form_control_box">
                    <Form.Label>Property</Form.Label>
                    <Form.Select
                      placeholder="Select Property"
                      id="city_id"
                      onChange={(e) => setPropertyId(e.target.value)}
                      value={propertyId}
                      aria-label="Default select example"
                    >
                      <option value="">Select Property</option>
                      {propertyData.map((property) => (
                        <>
                          <option value={property.id}>
                            {property.property_name}
                          </option>
                        </>
                      ))}
                    </Form.Select>
                  </div>
                </div>
                }
              </>
            )}
          </div>
        </Modal.Body>
        <>
          <Modal.Footer>
            <button
              type="button"
              className="orange_brd_button p-12-16-button mr-3"
              style={{ width: "fit-content" }}
              onClick={() => clearData()}
            >
              RESET
            </button>

            {verified && (
              <button
                type="button"
                className={"basic-button"}
                onClick={handleSubmit}
                disabled={submitLoader}
              >
                <div className="d-flex">
                  {submitLoader && <div className="loader-small"></div>}
                  <div>Create Lead</div>
                </div>
              </button>
            )}
          </Modal.Footer>
        </>
      </Modal>
    </div>
  );
};

export default MobileLeadModal;
