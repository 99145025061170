import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { updateWebsiteCarousel } from "../../../../helpers/services/websiteServices";
import toast from "react-hot-toast";
import MediaStorageModal from "./MediaStorageModal";

const ModalCrousel = ({
  carouselConfigData,
  carouselMediaStorage,
  handler,
  openModal,
  carousalIndex,
  actionType,
  additional_data,
  deleteModal,
  deleteModalHandler,
}) => {
  const [data, setData] = useState({
    title: "",
    description: "",
    web_image: "",
    mobile_image: "",
    order: "",
    link : "",
    button_text : "",
  });

  const [selectedImage, setSelectedImage] = useState(null);
  const [openMediaModal, setMediaModal] = useState(false);
  const [validationAttribute, setValidationAttribute] = useState(null);

  const handleSubmit = async () => {
    try {
      const orderExists = carouselConfigData.filter(
        (item, index) => item.order == data.order
      );
      if (
        orderExists.length > 0 &&
        carouselConfigData[carousalIndex].order != data.order
      ) {
        return toast.error("Order is already assigned to another item.");
      }

      if (actionType == "update") {
        carouselConfigData[carousalIndex] = data;
      } else if (actionType == "add") {
        carouselConfigData.push(data);
      }
      const bodyData = {
        config_details: carouselConfigData,
      };
      const response = await updateWebsiteCarousel(bodyData);
      if (response.status == "success") {
        toast.success(
          `${
            actionType == "update"
              ? `carousal # ${carousalIndex + 1} updated`
              : "New carousal added"
          }`
        );
        handler();
        setData({
          title: "",
          description: "",
          web_image: "",
          mobile_image: "",
          order: "",
          link : "",
          button_text : ""
        });
      } else {
        toast.error("Error while updation");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const clearData = () => {
    setData({
      title: "",
      description: "",
      image: "",
      order: "",
      link : "",
      button_text : ""
    });
    setSelectedImage(null);
  };

  useEffect(() => {
    if (actionType == "add") {
      clearData();
    }
  }, [actionType]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = () => {
      const imageUrl = reader.result;
      setSelectedImage(imageUrl);
    };
    reader.readAsDataURL(file);
  };

  const carousalData = async () => {
    if (actionType == "update") {
      const filterData = carouselConfigData.filter(
        (data, ind) => ind === carousalIndex
      );
      setData(filterData[0]);
    } else {
      setData({
        title: "",
        description: "",
        image: "",
        order: "",
        button_text : "",
        link : ""
      });
    }
  };

  useEffect(() => {
    carousalData();
  }, [carouselConfigData, carousalIndex, openModal]);

  const handleDelete = async () => {
    try {
      const filterData = carouselConfigData.filter(
        (data, index) => index !== carousalIndex
      );

      const bodyData = {
        config_details: filterData,
      };
      const response = await updateWebsiteCarousel(bodyData);
      if (response.status == "success") {
        toast.success("Carousel Deleted");
        deleteModalHandler(false);
      } else {
        toast.error("Error while updation");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <Modal
        show={openModal}
        onHide={() => {
          handler();
          clearData();
        }}
        centered
        size="xl"
        className="commen_modal"
      >
        <Modal.Header>
          <Modal.Title className="underline">
            {actionType == "add"
              ? "Add New Carousal"
              : `Carousal #${Number(carousalIndex) + 1}`}
          </Modal.Title>
          <img
            src="/images/cross_modal.svg"
            className="cursor-pointor rounded"
            alt="Close Icon"
            onClick={handler}
          />
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-6">
              {actionType == "add" && data?.web_image ? (
                <>
                  <img
                    src={data?.web_image}
                    alt=""
                    onClick={() => {
                      setValidationAttribute("web_image");
                      setMediaModal(true);
                    }}
                  />
                </>
              ) : (
                <>
                  {actionType == "add" && (
                    <>
                      <div
                        className="bg-light h-50 w-100 row container"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setMediaModal(true);
                          setValidationAttribute("web_image");
                        }}
                      >
                        <div className="col d-flex justify-content-center align-items-center">
                          Select Web Image
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
              <br />
              {actionType == "add" && data?.mobile_image ? (
                <>
                  <img
                    src={data?.mobile_image}
                    alt=""
                    onClick={() => {
                      setValidationAttribute("mobile_image");
                      setMediaModal(true);
                    }}
                  />
                </>
              ) : (
                <>
                  {actionType == "add" && (
                    <>
                      <div
                        className="bg-light h-50 w-100 row container"
                        style={{
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setMediaModal(true);
                          setValidationAttribute("mobile_image");
                        }}
                      >
                        <div className="col d-flex justify-content-center align-items-center">
                          Select Mobile Image
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
              {actionType == "update" && (
                <>
                  {" "}
                  {data?.web_image ? (
                    <>
                      <img
                        src={data?.web_image}
                        alt=""
                        onClick={() => {
                          setValidationAttribute("web_image");
                          setMediaModal(true);
                        }}
                        style={{cursor : "pointer"}}
                      />
                    </>
                  ) : (
                    <>
                      <div
                        className="col-lg-12 bg-light fs-1 mr-2 rounded"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "154px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setValidationAttribute("web_image");
                          setMediaModal(true);
                        }}
                      >
                        + Web Image
                      </div>
                    </>
                  )}
                  <br />
                  <br />
                  {data?.mobile_image ? (
                    <>
                      <img
                        src={data?.mobile_image}
                        alt=""
                        onClick={() => {
                          setValidationAttribute("mobile_image");
                          setMediaModal(true);
                        }}
                        style={{ height: "34vh" , cursor:"pointer" }}
                      />
                    </>
                  ) : (
                    <>
                      <div
                        className="col-lg-12 bg-light fs-1 mr-2 rounded"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "154px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setValidationAttribute("mobile_image");
                          setMediaModal(true);
                        }}
                      >
                        + Web Image
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
            <div className="col-6">
              <div className="form_control_box">
                <label htmlFor="title" className="form-label">
                  <b>Title</b>
                </label>
                <input
                  type="text"
                  id="title"
                  className="form-control mt-1"
                  name="title"
                  onChange={handleChange}
                  value={data?.title}
                />
              </div>
              <div className="form_control_box">
                <label htmlFor="button_text" className="form-label">
                  <b>Button Text</b>
                </label>
                <input
                  type="text"
                  id="button_text"
                  className="form-control mt-1"
                  name="button_text"
                  onChange={handleChange}
                  value={data?.button_text}
                />
              </div>
              <div className="form_control_box">
                <label htmlFor="link" className="form-label">
                  <b>Redirect link</b>
                </label>
                <input
                  type="text"
                  id="link"
                  className="form-control mt-1"
                  name="link"
                  onChange={handleChange}
                  value={data?.link}
                />
              </div>
              <div className="form_control_box">
                <label htmlFor="description" className="form-label">
                  <b>Description</b>
                </label>
                <textarea
                  type="text"
                  id="description"
                  className="form-control mt-1"
                  name="description"
                  onChange={handleChange}
                  value={data?.description}
                />
              </div>
              <div className="form_control_box">
                <label htmlFor="order" className="form-label">
                  <b>Order</b>
                </label>
                <input
                  type="number"
                  id="order"
                  className="form-control mt-1"
                  name="order"
                  onChange={handleChange}
                  value={Number(data?.order)}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            className={"basic-button"}
            onClick={handleSubmit}
          >
            {actionType == "add" ? "ADD NEW Carousel" : "UPDATE"}
          </button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={deleteModal}
        onHide={() => {
          deleteModalHandler(false);
        }}
        centered
        size="md"
        className="common_modal"
      >
        <Modal.Header>
          <b>Delete Carousel #{carousalIndex + 1}</b>
          <img
            src="/images/cross_modal.svg"
            className="cursor-pointer"
            alt="Close Icon"
            onClick={() => {
              deleteModalHandler(false);
            }}
          />
        </Modal.Header>
        <Modal.Body>Are you sure want to delete the carousel ?</Modal.Body>
        <Modal.Footer>
          <button className="basic-button" onClick={handleDelete}>
            Delete Carousel
          </button>
        </Modal.Footer>
      </Modal>

      <MediaStorageModal
        data={data}
        setData={setData}
        mediaData={carouselMediaStorage}
        openModal={openMediaModal}
        modalHandler={setMediaModal}
        additional_data={additional_data}
        validationAttribute={validationAttribute}
      />
    </div>
  );
};

export default ModalCrousel;
